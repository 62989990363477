body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.long {
  word-break: break-all;
}

.info-container p {
  font-size: 0.875rem;
}

.btn-scale {
  -webkit-transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
  -moz-transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
  transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
}

.btn-scale:hover {
  transform: scale(1.05);
  opacity: 1;
}

.donation-navbar {
  background: #009688;
  background: linear-gradient(90deg, #009688 0%, #0d47a1 100%);
}

.donation-text {
  color: white;
  font-size: 1.25rem;
  font-weight: 600;
}