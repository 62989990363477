.section-hero {
  background: #009688;
  background: linear-gradient(180deg, #009688 0%, #0d47a1 100%);
}

.row-download {
  background: #009688;
  background: linear-gradient(90deg, #009688 0%, #0d47a1 100%);
  padding-top: 3rem;
  padding-bottom: 3rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 3rem;
}

.download-title {
  color: white;
  font-weight: 700;
  font-size: 2.25rem;
}

.download-subtitle {
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.25rem;
}

.text-description {
  color: #ffffff;
  opacity: 0.7;
  font-size: 1.5rem;
}

.hr-divider {
  width: 4rem;
  border-color: #e1e1e1;
  border-width: 3px;
}

.img-hero {
  margin-bottom: -5rem;
}

.text-light-gray {
  color: #888888;
}

.text-blue {
  color: #064fa2;
}

.features-icon {
  width: 30px;
  height: 30px;
}

.app-icon {
  width: 88px;
  height: 88px;
  margin-right: 24px;
}

.app-logo {
  height: 30px;
}

.hero-left-col {
  padding: 2rem;
}

@media (min-width: 576px) {
  .section-hero {
    padding-top: 4rem;
  }
  .row-download {
    border-radius: 1.25rem;
  }
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}

@media (max-width: 575px) {
  .btn-download {
    width: 140px;
  }

  .app-icon {
    width: 72px;
    height: 72px;
  }

  .divider-arc svg {
    margin-top: 10px;
    height: 15px;
  }
}
