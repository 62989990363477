.footer {
  background: #ffffff;
}

.footer-legal-link {
  color: #555;
  text-decoration: none;
  font-size: 0.875rem;
}

.line-right {
  border-right: 1px solid #d6d6d6;
  margin-right: 8px;
  padding-right: 9px;
}
